import React, { useEffect, useRef, useState } from "react";
import { GetStageImage } from "@/helper/stageImage";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export function Rocket() {
  const showLunchAudio = useRef<HTMLAudioElement>(null);
  const showLunchRiseAudio = useRef<HTMLAudioElement>(null);

  const state = useSelector((state: RootState) => state.StageState);
  const [launch, setLaunch] = useState<string>("progress_launch");
  const [looping, setLooping] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if(state?.isLaunch) {
      videoRef.current!.play()
      // setTimeout(() => {
      //   showLunchAudio.current?.play()
      //   setLaunch("progress_launch progress_launched");
      // }, 500);
    }
    else {
      // setLaunch("progress_launch");
      // setLooping(false);
    }

  }, [state?.isLaunch])

  function onEnded() {
    //setLooping(true)
  }

  function onEndSound() {
    setTimeout(() => {
      showLunchRiseAudio.current?.play()
    }, 100);
  }

  return (
    <>
      {
        state?.mainStageLaunchRocket?
          <div className={"rocket"}>
            <video className="video_view" ref={videoRef}
                   onEnded={onEnded}
                   src="/static/image/stage_rocket_launch/launch_video.mp4"
                   muted={false} autoFocus={true} autoPlay={false} playsInline={true}/>
            <img className="w-100" src={GetStageImage("rocket_background")} alt={"bg"}/>
            <img className="hint" src={GetStageImage("rocket_hint")} alt={"bg"}/>
            {/*<img className="progress_background" src={GetStageImage("rocket_progress_background")} alt={"bg"}/>*/}
            {/*<img className={launch} src={GetStageImage("rocket_progress")} alt={"bg"}/>*/}
            {/*<img className="rocket_state1" src={GetStageImage("rocket_state1")} alt={"bg"}/>*/}
            {/*<img className="rocket_state2" src={GetStageImage("rocket_state2")} alt={"bg"}/>*/}
            {/*<img className="rocket_state3" src={GetStageImage("rocket_state3")} alt={"bg"}/>*/}

            <video className={looping ? "video_loop" : "video_loop d-none"}
                   src="/static/image/stage_rocket_launch/rocket_fly.mp4"
                   muted={true} autoFocus={true} autoPlay={true} playsInline={true} loop={true}/>
          </div>
          :null
      }
    </>

  )
}