import React, { useEffect, useState } from "react";
import 'mind-ar/dist/mindar-image-three.prod';
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "@/store";
import { GetViewerImage } from "@/helper/viewerImage";
import { arLib } from "@/model/viewerState";
import { imageTake } from "@/helper/imageTake";

export function Title() {
  const state = useSelector((state: RootState) => state.ViewerState);
  const dispatch = useDispatch<Dispatch>();
  const [imageData, setImageData] = useState<string>("");

  useEffect(() => {
    if(state?.isStart) {
      setTimeout(() => {
        dispatch.ViewerState.setImageTracking();
      }, 1000)
    }
    else {
      dispatch.ViewerState.stopImageTracking();
    }
    return function clearUp() {
      //arLib.stop();
    }
  }, [state?.isStart]);

  function OnClickImage() {
    console.log(`take image`)
    if(arLib){
      const data = imageTake(arLib)
      setImageData(data[0] as string);
    }
  }

  function OnClickCloseImage() {
    setImageData("")
  }

  return (
    <div className={state?.isStart?"ar_view":"d-none"}>
      <div id="ar_container" style={{ width: "100%", height: "100vh" }} />
      <img className="c_left_up" src={GetViewerImage("c_left_up")} alt={"bg"} />
      <img className="c_right_up" src={GetViewerImage("c_right_up")} alt={"bg"} />
      <img className="c_left_down" src={GetViewerImage("c_left_down")} alt={"bg"} />
      <img className="c_right_down" src={GetViewerImage("c_right_down")} alt={"bg"} />
      <img className="text" src={GetViewerImage("text_shot")} alt={"bg"} />
      <img className="take-image" onClick={OnClickImage} src={GetViewerImage("button_shot")} alt={"bg"} />

      {
        imageData!=""?
            <div className="image-view">
              <span className="imageSet save_text">長按照片儲存</span>
              <div className="w-50 imageSet" style={{height: "50%"}}>
                <img className="w-100 imageSet" src={imageData} alt={"bg"} />
                <img className="c_left_up" style={{top: 0, width: '20%'}} src={GetViewerImage("c_left_up")} alt={"bg"} />
                <img className="c_right_up" style={{top: 0, width: '20%'}} src={GetViewerImage("c_right_up")} alt={"bg"} />
                <img className="c_left_down" style={{bottom: 0, width: '20%'}} src={GetViewerImage("c_left_down")} alt={"bg"} />
                <img className="c_right_down" style={{bottom: 0, width: '20%'}} src={GetViewerImage("c_right_down")} alt={"bg"} />
              </div>

              <img className="imageSet close-image" onClick={OnClickCloseImage}
                   src={GetViewerImage("button_close")} alt={"bg"} />
            </div>
            :null
      }


    </div>
  )
}