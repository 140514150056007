import { useSelector } from "react-redux";
import { RootState } from "@/store";
import React from "react";

export function AnimationView() {
  const state = useSelector((state: RootState) => state.StageState);

  return (
    <>
      {
        state?.animationView?
          <div className="rocket" style={{backgroundColor: "black", minHeight: 1080}}>
            <video className={"mx-auto"}
                   style={{height: 1080, maxHeight: 1080,
                     position: "absolute", top: '50%', left: '50%', transform: "translate(-50%, -50%)"}}
                   src="/static/video/stage-animation.mp4" loop={true}
                   muted={true} autoFocus={true} autoPlay={true} playsInline={true}/>

          </div>
          :null
      }
    </>
  )
}