import React, { useEffect, useState } from "react";
import "bootstrap/scss/bootstrap.scss";
import { Socket } from "socket.io-client";
import { initSocketIO } from "@/helper/socket";


let socketIO: Socket | null = null;

interface IStageState {
  isLoading: boolean;
  isStart: boolean;
  isWaiting: boolean;
  isSelectingCard: boolean;
  isShowSelectedCard: boolean;
  isShowCard: boolean;
  selectedNumber: number;
  isShowQuest: boolean;
  isShowAns: boolean;
  isShowLaunchRocket: boolean;
  isShowEndAr: boolean;
  isLaunch: boolean;
}

interface IStageStep {
  mainStage1: boolean;
  mainStage2: boolean;
  mainStageSelectCard: boolean;
  isSelectCard: boolean;
  isShowCard: boolean;
  isShowQuest: boolean;
  isShowAns: boolean;
  mainStageLaunchRocket: boolean;
  questNumber: number;
  animationView: boolean;
  imageView: boolean;
  isLaunch: boolean;
}

export function Controller() {
  const [questNumber, setQuestNumber] = useState<string>("1");
  useEffect(() => {
    setTimeout(() => {
      socketIO = initSocketIO();
    }, 1000);
  }, []);

  function SetDefaultViwerView() {
    const data: IStageState = {
      isLoading: false,
      isStart: true,
      isWaiting: false,
      isSelectingCard: false,
      isShowSelectedCard: false,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: false,
      isShowAns: false,
      isShowLaunchRocket: false,
      isShowEndAr: false,
      isLaunch: false
    }
    socketIO?.emit("set_init_data", data)
  }

  function SwitchStartARView() {
    const data: IStageState = {
      isLoading: false,
      isStart: true,
      isWaiting: false,
      isSelectingCard: false,
      isShowSelectedCard: false,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: false,
      isShowAns: false,
      isShowLaunchRocket: false,
      isShowEndAr: false,
      isLaunch: false
    }
    socketIO?.emit("switch_viewer", data)
  }

  function SwitchViewer() {
    const data: IStageState = {
      isLoading: false,
      isStart: false,
      isWaiting: true,
      isSelectingCard: false,
      isShowSelectedCard: false,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: false,
      isShowAns: false,
      isShowLaunchRocket: false,
      isShowEndAr: false,
      isLaunch: false
    }
    socketIO?.emit("switch_viewer", data)
  }

  function SwitchSelectCard() {
    const data: IStageState = {
      isLoading: false,
      isStart: false,
      isWaiting: false,
      isSelectingCard: true,
      isShowSelectedCard: true,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: false,
      isShowAns: false,
      isShowLaunchRocket: false,
      isShowEndAr: false,
      isLaunch: false
    }
    socketIO?.emit("switch_viewer", data)
  }

  function SwitchShowCard() {
    const data: IStageState = {
      isLoading: false,
      isStart: false,
      isWaiting: false,
      isSelectingCard: true,
      isShowSelectedCard: false,
      isShowCard: true,
      selectedNumber: +questNumber,
      isShowQuest: false,
      isShowAns: false,
      isShowLaunchRocket: false,
      isShowEndAr: false,
      isLaunch: false
    }
    socketIO?.emit("switch_viewer", data)
  }

  function SwitchQuest() {
    const data: IStageState = {
      isLoading: false,
      isStart: false,
      isWaiting: false,
      isSelectingCard: true,
      isShowSelectedCard: false,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: true,
      isShowAns: false,
      isShowLaunchRocket: false,
      isShowEndAr: false,
      isLaunch: false
    }
    socketIO?.emit("switch_viewer", data)
  }

  function SwitchViewerAns() {
    const data: IStageState = {
      isLoading: false,
      isStart: false,
      isWaiting: false,
      isSelectingCard: true,
      isShowSelectedCard: false,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: true,
      isShowAns: true,
      isShowLaunchRocket: false,
      isShowEndAr: false,
      isLaunch: false
    }
    socketIO?.emit("switch_viewer", data)
  }

  function SwitchRocketLaunch() {
    const data: IStageState = {
      isLoading: false,
      isStart: false,
      isWaiting: false,
      isSelectingCard: false,
      isShowSelectedCard: false,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: false,
      isShowAns: false,
      isShowLaunchRocket: true,
      isShowEndAr: false,
      isLaunch: false
    }
    socketIO?.emit("switch_viewer", data)
  }

  function SwitchEndAR() {
    const data: IStageState = {
      isLoading: false,
      isStart: false,
      isWaiting: false,
      isSelectingCard: false,
      isShowSelectedCard: false,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: false,
      isShowAns: false,
      isShowLaunchRocket: false,
      isShowEndAr: true,
      isLaunch: false
    }
    socketIO?.emit("switch_viewer", data)
  }

  function SwitchStage1() {
    const data: IStageStep = {
      mainStage1: true,
      mainStage2: false,
      mainStageSelectCard: false,
      isSelectCard: false,
      isShowCard: false,
      isShowQuest: false,
      isShowAns: false,
      mainStageLaunchRocket: false,
      questNumber: +questNumber,
      animationView: false,
      imageView: false,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchStage2() {
    const data: IStageStep = {
      mainStage1: true,
      mainStage2: true,
      mainStageSelectCard: false,
      isSelectCard: false,
      isShowCard: false,
      isShowQuest: false,
      isShowAns: false,
      mainStageLaunchRocket: false,
      questNumber: +questNumber,
      animationView: false,
      imageView: false,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchStageSelectCard() {
    const data: IStageStep = {
      mainStage1: false,
      mainStage2: false,
      mainStageSelectCard: true,
      isSelectCard: true,
      isShowCard: false,
      isShowQuest: false,
      isShowAns: false,
      mainStageLaunchRocket: false,
      questNumber: +questNumber,
      animationView: false,
      imageView: false,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchShowSelectCard() {
    const data: IStageStep = {
      mainStage1: false,
      mainStage2: false,
      mainStageSelectCard: true,
      isSelectCard: false,
      isShowCard: true,
      isShowQuest: false,
      isShowAns: false,
      mainStageLaunchRocket: false,
      questNumber: +questNumber,
      animationView: false,
      imageView: false,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchShowQuest() {
    const data: IStageStep = {
      mainStage1: false,
      mainStage2: false,
      mainStageSelectCard: true,
      isSelectCard: false,
      isShowCard: false,
      isShowQuest: true,
      isShowAns: false,
      mainStageLaunchRocket: false,
      questNumber: +questNumber,
      animationView: false,
      imageView: false,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchShowAns() {
    const data: IStageStep = {
      mainStage1: false,
      mainStage2: false,
      mainStageSelectCard: true,
      isSelectCard: false,
      isShowCard: false,
      isShowQuest: true,
      isShowAns: true,
      mainStageLaunchRocket: false,
      questNumber: +questNumber,
      animationView: false,
      imageView: false,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchStageRocketLaunch() {
    const data: IStageStep = {
      mainStage1: false,
      mainStage2: false,
      mainStageSelectCard: false,
      isSelectCard: false,
      isShowCard: false,
      isShowQuest: false,
      isShowAns: false,
      mainStageLaunchRocket: true,
      questNumber: +questNumber,
      animationView: false,
      imageView: false,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchAnimation() {
    const data: IStageStep = {
      mainStage1: false,
      mainStage2: false,
      mainStageSelectCard: false,
      isSelectCard: false,
      isShowCard: false,
      isShowQuest: false,
      isShowAns: false,
      mainStageLaunchRocket: false,
      questNumber: +questNumber,
      animationView: true,
      imageView: false,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchImageView() {
    const data: IStageStep = {
      mainStage1: false,
      mainStage2: false,
      mainStageSelectCard: false,
      isSelectCard: false,
      isShowCard: false,
      isShowQuest: false,
      isShowAns: false,
      mainStageLaunchRocket: false,
      questNumber: +questNumber,
      animationView: false,
      imageView: true,
      isLaunch: false
    }

    socketIO?.emit("switch_stage", data)
  }

  function SwitchLaunch() {
    const dataStage: IStageStep = {
      mainStage1: false,
      mainStage2: false,
      mainStageSelectCard: false,
      isSelectCard: false,
      isShowCard: false,
      isShowQuest: false,
      isShowAns: false,
      mainStageLaunchRocket: true,
      questNumber: +questNumber,
      animationView: false,
      imageView: false,
      isLaunch: true
    }
    const data: IStageState = {
      isLoading: false,
      isStart: false,
      isWaiting: false,
      isSelectingCard: false,
      isShowSelectedCard: false,
      isShowCard: false,
      selectedNumber: +questNumber,
      isShowQuest: false,
      isShowAns: false,
      isShowLaunchRocket: true,
      isShowEndAr: false,
      isLaunch: true
    }

    socketIO?.emit("switch_viewer", data)
    socketIO?.emit("switch_stage", dataStage)
  }

  function OnChangeQuest(evt: React.ChangeEvent<HTMLSelectElement>){
    console.log(evt.currentTarget.value)
    setQuestNumber(evt.currentTarget.value)
  }

  return (
    <div className="container-sm">
      問題:
      <select className="form-select my-2" onChange={OnChangeQuest}
              value={questNumber} aria-label="Default select example">
        <option value="1">第一題</option>
        <option value="2">第二題</option>
        <option value="3">第三題</option>
        <option value="4">第四題</option>
        <option value="5">第五題</option>
      </select>
      觀眾畫面
      <div className="row mt-2 gy-2">
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SetDefaultViwerView}>
            初始化觀眾端
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchStartARView}>
            切換首頁AR
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchViewer}>
            切換等待畫面
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchSelectCard}>
            切換選擇卡片
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchShowCard}>
            切換抽卡畫面
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchQuest}>
            切換問題畫面
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchViewerAns}>
            切換顯示答案畫面
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchRocketLaunch}>
            切換集氣畫面
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchEndAR}>
            切換最後一頁
          </div>
        </div>
      </div>

      <p className={"mt-2"}>舞台畫面</p>
      <div className="row gy-3">
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchStage1}>
            切換主畫面 狀態一
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchStage2}>
            切換主畫面 狀態二
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchStageSelectCard}>
            切換選擇卡片
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchShowSelectCard}>
            切換抽卡畫面
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchShowQuest}>
            切換問題畫面
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchShowAns}>
            切換顯示答案畫面
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-primary mx-1" onClick={SwitchStageRocketLaunch}>
            切換集氣畫面
          </div>
        </div>
      </div>

      <p className={"mt-2"}>舞台其他畫面</p>
      <div className="row gy-3">
        <div className="col-3">
          <div className="btn btn-danger mx-1" onClick={SwitchAnimation}>
            切換 特斯拉線圈動畫
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-danger mx-1" onClick={SwitchImageView}>
            切換 5987
          </div>
        </div>
        <div className="col-3">
          <div className="btn btn-danger mx-1" onClick={SwitchLaunch}>
            發射火箭
          </div>
        </div>
      </div>
    </div>
  )
}