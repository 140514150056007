import { useSelector } from "react-redux";
import { RootState } from "@/store";
import React from "react";
import { GetStageImage } from "@/helper/stageImage";

export function ImageView() {
  const state = useSelector((state: RootState) => state.StageState);

  return (
    <>
      {
        state?.imageView?
          <div className="rocket" style={{backgroundColor: "black", minHeight: 1080}}>
            <img className="w-100" src={GetStageImage("img_5987")} alt={"bg"}/>
          </div>
          :null
      }
    </>
  )
}