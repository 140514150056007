import { createModel, RematchDispatch } from "@rematch/core";
import { RootModel } from "@/model/index";
import * as THREE from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Socket } from "socket.io-client";
import { initSocketIO } from "@/helper/socket";

const mixer:any[] = [];
export const animationList: any[] = [];
const clock = new THREE.Clock();
export let modelData:THREE.Group|undefined = undefined;

let socketIO: Socket|null = null;

interface IStageState {
  isLoading: boolean;
  isStart: boolean;
  isWaiting: boolean;
  isSelectingCard: boolean;
  isShowSelectedCard: boolean;
  isShowCard:boolean,
  selectedNumber: number;
  isShowQuest: boolean,
  isShowAns: boolean;
  isShowLaunchRocket: boolean;
  isShowEndAr: boolean;
  isLaunch: boolean;
}

export let arLib: any = null;

export const ViewerState = createModel<RootModel>()({
  state: {
    isLoading: true,
    isStart: false,
    isWaiting: false,
    isSelectingCard: false,
    isShowSelectedCard: false,
    isShowCard:false,
    selectedNumber: 1,
    isShowQuest: false,
    isShowAns: false,
    isShowLaunchRocket: false,
    isShowEndAr: false,
    isLaunch: false
  } as IStageState,
  reducers: {
    setState(state, payload: { type: string, data: any }) {
      const {type, data} = payload;
      return {...state, [type]: data};
    },
    setData(state, payload: IStageState) {
      console.log({...state, ...payload})
      return {...state, ...payload};
    }
  },
  effects: (dispatch) => ({
    async setImageTracking() {
      if(arLib) {
        return;
      }
      //@ts-ignore
      arLib = new window.MINDAR.IMAGE.MindARThree({
        container: document.querySelector("#ar_container"),
        imageTargetSrc: '/static/model/targets',
        filterMinCF: 0.001,
        filterBeta: 0.009,
        missTolerance: 5,
        warmupTolerance: 0,
        uiError: false,
        uiLoading: false,
        uiScanning: false
      })

      const {renderer, scene, camera} = arLib;
      const anchor = arLib.addAnchor(0);
      modelData = anchor.group as THREE.Group;
      //@ts-ignore
      window.modelData = modelData;
      anchor.onTargetFound = ()=> {
        console.log(`found image`)
      }
      const loader = new GLTFLoader();
      Promise.all([
        loader.loadAsync("/static/model/blackhole_v2.glb"),
        arLib.start()
      ]).then(([blackhole, arLibResult])=> {
        console.log(blackhole)
        //set camera
        setWebCam(arLib);
        //set scene
        setScene(anchor.group, scene, blackhole, () => {
          renderer.shadowMap.enabled = true;
          renderer.shadowMap.type = THREE.PCFSoftShadowMap;
          renderer.shadowMap.needsUpdate = true;
          renderer.setAnimationLoop(() => {
            renderer.autoClear = false;
            camera.layers.set(2);
            renderer.render(scene, camera);
            camera.layers.set(0);
            renderer.render(scene, camera);
            let mixerUpdateDelta = clock.getDelta();
            Object.keys(mixer).forEach(name => {
              //@ts-ignore
              mixer[name].update(mixerUpdateDelta)
            })
          })
        });
      })
    },
    setSocketConnect() {
      if(socketIO == null) {
        socketIO = initSocketIO()
        setWebSocketEvent(dispatch);
      }
    },
    stopImageTracking() {
      if(arLib != null) {
        arLib.stop()
        arLib.renderer.setAnimationLoop(null)
        arLib = null;
      }
    }
  })
});

function setWebCam(mindarThree: any) {
  const { video, scene } = mindarThree;
  video.style.opacity = 0;
  //建立影像圖層
  let videoTex = new THREE.VideoTexture(video);
  videoTex.colorSpace = THREE.SRGBColorSpace;
  videoTex.minFilter = THREE.LinearFilter;

  const videoMesh = new THREE.Mesh(
    new THREE.PlaneGeometry(video.clientWidth, video.clientHeight),
    new THREE.MeshBasicMaterial({ color: 0xffffff, map: videoTex, side: THREE.DoubleSide})
  );

  let scale = 13.5;
  let position_y = 0;
  videoMesh.position.set(0, position_y, -10000);
  videoMesh.scale.set(scale, scale, 1);
  videoMesh.layers.set(2);
  videoMesh.layers.enable(2);
  scene.add(videoMesh);
}

function setScene(anchorGroup: THREE.Group, scene: THREE.Scene, model: GLTF, callback: ()=>void) {
  const modelObject = new THREE.Object3D();
  modelObject.add(model.scene)
  modelObject.scale.set(0.0235, 0.0235, 0.0235);
  modelObject.rotation.y = 90
  anchorGroup.add(modelObject);
  const light = new THREE.AmbientLight(0xffffff, 1.0);
  scene.add(light)

  model.animations.forEach(item => {
    console.log(item)
    //@ts-ignore
    mixer[item.name] = new THREE.AnimationMixer(model.scene);
    //@ts-ignore
    animationList[item.name] = mixer[item.name].clipAction(item);
    //@ts-ignore
    animationList[item.name].play();
    //@ts-ignore
    animationList[item.name].loop = THREE.LoopRepeat;
  })

  callback();
}

function setWebSocketEvent(dispatch: RematchDispatch<RootModel>) {
  if(socketIO === null){
    return;
  }

  socketIO.on("connect", () => {
    // dispatch.ViewerState.setState({type:"isLoading", data:false});
    console.log(`connect`)
  });

  socketIO.on("init_data", (data: IStageState) => {

    dispatch.ViewerState.setData(data);
  })

  socketIO.on("switch_viewer", (data: IStageState) => {
    dispatch.ViewerState.setData(data);
  })
}