import React, {useEffect} from "react";
import { initSocketIO } from "@/helper/socket";
import { Socket } from "socket.io-client";
import { FaceTrackARView, Question, Rocket, Title, Wait } from "@/component/Viewer";
import "@/scss/viewer.scss"
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@/store";
import { ViewerImage } from "@/helper/viewerImage";

let socketIO: Socket|null = null;

export function Viewer() {
  const state = useSelector((state: RootState) => state.ViewerState);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    const loadImage = (image:{src: string}) => {
      return new Promise((resolve, reject)=> {
        const loadImg = new Image();
        loadImg.src = image.src;
        loadImg.onload = () => {
          resolve(image.src)
        }

        loadImg.onerror = err => reject(err);
      })
    }

    Promise.all(ViewerImage.map(image => loadImage(image)))
        .then(() => {
          setTimeout(() => {
            dispatch.ViewerState.setSocketConnect();
          }, 2000);
          //dispatch.ViewerState.setState({type:"isLoading", data:false});
          //dispatch.ViewerState.setState({type:"isStart", data: true});
    })

  }, []);

  return (
    <div className="container-sm viewer">
      {
        state?.isLoading?
          <div>loading</div>:
          <>
            <Title />
            <Wait />
            <Question />
            <Rocket />
            <FaceTrackARView />
          </>
      }

    </div>
  )
}