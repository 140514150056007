import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Viewer, Stage, Controller } from "@/view";

const Routers = createBrowserRouter([
  {
    path: `/`,
    element: <Viewer />
  },
  {
    path: `/stage`,
    element: <Stage />
  },
  {
    path: `/controller`,
    element: <Controller />
  }
])

export default Routers;