import React, { useEffect, useRef, useState } from "react";
import { GetStageImage, StageImage } from "@/helper/stageImage";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export function Title() {
    const state = useSelector((state: RootState) => state.StageState);
    const showCardAudio = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if(state?.mainStage2) {
            showCardAudio.current?.play()
        }else {
            showCardAudio.current?.pause()
            showCardAudio.current!.currentTime = 0;
        }
    }, [state?.mainStage2])

    return (
        <>
            <audio ref={showCardAudio} src="/static/sound/Space 7c.wav"
                   autoPlay={false} playsInline={true}></audio>
            <div className={state?.mainStage1?"title position-relative":"d-none"}>
                <img className="w-100" src={GetStageImage("background")} alt={"bg"}/>
                <img className="cire" src={GetStageImage("Star")} alt={"bg"} />
                <img className="cire" src={GetStageImage("background_cire")} alt={"bg"} />

                <img className="plant01" src={GetStageImage("planet_01")} alt={"bg"} />
                <img className="plant02" src={GetStageImage("planet_02")} alt={"bg"} />
                <img className="plant03" src={GetStageImage("planet_03")} alt={"bg"} />

                <img className={state?.mainStage2?"d-none":"loading-background"} src={GetStageImage("loading_background")} alt={"bg"} />
                <img className={state?.mainStage2?"d-none":"loading-bar"} src={GetStageImage("loading")} alt={"bg"} />

                <img className="title-words" src={GetStageImage("T")} alt={"bg"} />
                <img className={state?.mainStage2?"title-G set-G ":"title-G"} src={GetStageImage("G")} alt={"bg"} />
                <img className={state?.mainStage2?"title-8 set":"title-8"} src={GetStageImage("8")} alt={"bg"} />
            </div>
        </>

    )
}