export const ViewerImage = [
  {
    name: "background",
    src: "/static/image/viewer_wait_view/BG.png"
  },
  {
    name: "background_star",
    src: "/static/image/viewer_wait_view/BG_Star.png"
  },
  {
    name: "message_box",
    src: "/static/image/viewer_wait_view/MessageBox.png"
  },
  {
    name: "ricket",
    src: "/static/image/viewer_wait_view/Ricket.png"
  },
  {
    name: "black_hole",
    src: "/static/image/viewer_wait_view/BlackHole.png"
  },
  {
    name: "earth",
    src: "/static/image/viewer_wait_view/Earth.png"
  },

  {
    name: "quest_background",
    src: "/static/image/viewer_select_card_view/BG.png"
  },
  {
    name: "base_select_card",
    src: "/static/image/viewer_select_card_view/base.png"
  },
  {
    name: "base_light_select_card",
    src: "/static/image/viewer_select_card_view/base_light.png"
  },
  {
    name: "base_wave_select_card",
    src: "/static/image/viewer_select_card_view/base_wave.png"
  },
  {
    name: "card_01(R)",
    src: "/static/image/viewer_select_card_view/card_01(R).png"
  },
  {
    name: "card_01(R1)",
    src: "/static/image/viewer_select_card_view/card_01(R1).png"
  },
  {
    name: "card_02(B)",
    src: "/static/image/viewer_select_card_view/card_02(B).png"
  },
  {
    name: "card_02(B1)",
    src: "/static/image/viewer_select_card_view/card_02(B1).png"
  },
  {
    name: "card_03(P)",
    src: "/static/image/viewer_select_card_view/card_03(P).png"
  },
  {
    name: "card_03(P1)",
    src: "/static/image/viewer_select_card_view/card_03(P1).png"
  },
  {
    name: "card_04(Y)",
    src: "/static/image/viewer_select_card_view/card_04(Y).png"
  },
  {
    name: "card_04(Y1)",
    src: "/static/image/viewer_select_card_view/card_04(Y1).png"
  },
  {
    name: "card_05(G)",
    src: "/static/image/viewer_select_card_view/card_05(G).png"
  },
  {
    name: "card_05(G1)",
    src: "/static/image/viewer_select_card_view/card_05(G1).png"
  },

  {
    name: "background_Q1",
    src: "/static/image/viewer_question_view/Q1/background.png"
  },
  {
    name: "quest_img_Q1",
    src: "/static/image/viewer_question_view/Q1/quest_img.png"
  },
  {
    name: "Q1_A",
    src: "/static/image/viewer_question_view/Q1/A.png"
  },
  {
    name: "Q1_A_Select",
    src: "/static/image/viewer_question_view/Q1/A_A.png"
  },
  {
    name: "Q1_A_Ans",
    src: "/static/image/viewer_question_view/Q1/A_Ans.png"
  },
  {
    name: "Q1_B",
    src: "/static/image/viewer_question_view/Q1/B.png"
  },
  {
    name: "Q1_B_Select",
    src: "/static/image/viewer_question_view/Q1/A_B.png"
  },
  {
    name: "Q1_B_Ans",
    src: "/static/image/viewer_question_view/Q1/B_Ans.png"
  },
  {
    name: "Q1_C",
    src: "/static/image/viewer_question_view/Q1/C.png"
  },
  {
    name: "Q1_C_Select",
    src: "/static/image/viewer_question_view/Q1/A_C.png"
  },
  {
    name: "Q1_C_Ans",
    src: "/static/image/viewer_question_view/Q1/C_Ans.png"
  },
  {
    name: "background_Q2",
    src: "/static/image/viewer_question_view/Q2/background.png"
  },
  {
    name: "quest_img_Q2",
    src: "/static/image/viewer_question_view/Q2/quest_img.png"
  },
  {
    name: "Q2_A",
    src: "/static/image/viewer_question_view/Q2/A.png"
  },
  {
    name: "Q2_A_Ans",
    src: "/static/image/viewer_question_view/Q2/A_Ans.png"
  },
  {
    name: "Q2_B",
    src: "/static/image/viewer_question_view/Q2/B.png"
  },
  {
    name: "Q2_B_Ans",
    src: "/static/image/viewer_question_view/Q2/B_Ans.png"
  },
  {
    name: "Q2_C",
    src: "/static/image/viewer_question_view/Q2/C.png"
  },
  {
    name: "Q2_C_Ans",
    src: "/static/image/viewer_question_view/Q2/C_Ans.png"
  },

  {
    name: "background_Q3",
    src: "/static/image/viewer_question_view/Q3/background.png"
  },
  {
    name: "quest_img_Q3",
    src: "/static/image/viewer_question_view/Q3/quest_img.png"
  },
  {
    name: "Q3_A",
    src: "/static/image/viewer_question_view/Q3/A.png"
  },
  {
    name: "Q3_A_Ans",
    src: "/static/image/viewer_question_view/Q3/A_Ans.png"
  },
  {
    name: "Q3_B",
    src: "/static/image/viewer_question_view/Q3/B.png"
  },
  {
    name: "Q3_B_Ans",
    src: "/static/image/viewer_question_view/Q3/B_Ans.png"
  },
  {
    name: "Q3_C",
    src: "/static/image/viewer_question_view/Q3/C.png"
  },
  {
    name: "Q3_C_Ans",
    src: "/static/image/viewer_question_view/Q3/C_Ans.png"
  },

  {
    name: "background_Q4",
    src: "/static/image/viewer_question_view/Q4/background.png"
  },
  {
    name: "quest_img_Q4",
    src: "/static/image/viewer_question_view/Q4/quest_img.png"
  },
  {
    name: "Q4_A",
    src: "/static/image/viewer_question_view/Q4/A.png"
  },
  {
    name: "Q4_A_Ans",
    src: "/static/image/viewer_question_view/Q4/A_Ans.png"
  },
  {
    name: "Q4_B",
    src: "/static/image/viewer_question_view/Q4/B.png"
  },
  {
    name: "Q4_B_Ans",
    src: "/static/image/viewer_question_view/Q4/B_Ans.png"
  },
  {
    name: "Q4_C",
    src: "/static/image/viewer_question_view/Q4/C.png"
  },
  {
    name: "Q4_C_Ans",
    src: "/static/image/viewer_question_view/Q4/C_Ans.png"
  },

  {
    name: "background_Q5",
    src: "/static/image/viewer_question_view/Q5/background.png"
  },
  {
    name: "quest_img_Q5",
    src: "/static/image/viewer_question_view/Q5/quest_img.png"
  },
  {
    name: "Q5_A",
    src: "/static/image/viewer_question_view/Q5/A.png"
  },
  {
    name: "Q5_A_Ans",
    src: "/static/image/viewer_question_view/Q5/A_Ans.png"
  },
  {
    name: "Q5_B",
    src: "/static/image/viewer_question_view/Q5/B.png"
  },
  {
    name: "Q5_B_Ans",
    src: "/static/image/viewer_question_view/Q5/B_Ans.png"
  },
  {
    name: "Q5_C",
    src: "/static/image/viewer_question_view/Q5/C.png"
  },
  {
    name: "Q5_C_Ans",
    src: "/static/image/viewer_question_view/Q5/C_Ans.png"
  },

  {
    name: "loading_layout",
    src: "/static/image/viewer_rocket_launch/loading_layout.png"
  },
  {
    name: "loading",
    src: "/static/image/viewer_rocket_launch/loading.png"
  },
  {
    name: "keep_it_up",
    src: "/static/image/viewer_rocket_launch/keep_it_up.png"
  },
  {
    name: "hint",
    src: "/static/image/viewer_rocket_launch/hint.png"
  },
  {
    name: "text_shot",
    src: "/static/image/viewer_ar/text_shot.png"
  },
  {
    name: "button_close",
    src: "/static/image/viewer_ar/button_close.png"
  },
  {
    name: "button_shot",
    src: "/static/image/viewer_ar/button_shot.png"
  },
  {
    name: "button_switch",
    src: "/static/image/viewer_ar/button_switch.png"
  },
  {
    name: "c_left_down",
    src: "/static/image/viewer_ar/c_left_down.png"
  },
  {
    name: "c_left_up",
    src: "/static/image/viewer_ar/c_left_up.png"
  },
  {
    name: "c_right_up",
    src: "/static/image/viewer_ar/c_right_up.png"
  },
  {
    name: "c_right_down",
    src: "/static/image/viewer_ar/c_right_down.png"
  },
  {
    name: "50",
    src: "/static/image/viewer_ar/50.png"
  },
  {
    name: "60",
    src: "/static/image/viewer_ar/60.png"
  },
  {
    name: "70",
    src: "/static/image/viewer_ar/70.png"
  },
  {
    name: "80",
    src: "/static/image/viewer_ar/80.png"
  },
  {
    name: "90",
    src: "/static/image/viewer_ar/90.png"
  },
  {
    name: "100",
    src: "/static/image/viewer_ar/100.png"
  },
  {
    name: "score_text",
    src: "/static/image/viewer_ar/score_text.png"
  },
  {
    name: "Q2_A_Select",
    src: "/static/image/viewer_question_view/Q2/A_A.png"
  },
  {
    name: "Q2_B_Select",
    src: "/static/image/viewer_question_view/Q2/A_B.png"
  },
  {
    name: "Q2_C_Select",
    src: "/static/image/viewer_question_view/Q2/A_C.png"
  },

  {
    name: "Q3_A_Select",
    src: "/static/image/viewer_question_view/Q3/A_A.png"
  },
  {
    name: "Q3_B_Select",
    src: "/static/image/viewer_question_view/Q3/A_B.png"
  },
  {
    name: "Q3_C_Select",
    src: "/static/image/viewer_question_view/Q3/A_C.png"
  },

  {
    name: "Q4_A_Select",
    src: "/static/image/viewer_question_view/Q4/A_A.png"
  },
  {
    name: "Q4_B_Select",
    src: "/static/image/viewer_question_view/Q4/A_B.png"
  },
  {
    name: "Q4_C_Select",
    src: "/static/image/viewer_question_view/Q4/A_C.png"
  },

  {
    name: "Q5_A_Select",
    src: "/static/image/viewer_question_view/Q5/A_A.png"
  },
  {
    name: "Q5_B_Select",
    src: "/static/image/viewer_question_view/Q5/A_B.png"
  },
  {
    name: "Q5_C_Select",
    src: "/static/image/viewer_question_view/Q5/A_C.png"
  },
]

export const GetViewerImage = (name: string) => {
  return ViewerImage.find(item => item.name === name)!.src;
}

export const GetCardName = (quest: number, isSelectCard: boolean) => {
  let rtn = "";
  switch (quest){
    case 1:
      rtn = isSelectCard?"card_01(R1)":"card_01(R)";
      break;
    case 2:
      rtn = isSelectCard?"card_02(B1)":"card_02(B)";
      break;
    case 3:
      rtn = isSelectCard?"card_03(P1)":"card_03(P)";
      break;
    case 4:
      rtn = isSelectCard?"card_04(Y1)":"card_04(Y)";
      break;
    case 5:
      rtn = isSelectCard?"card_05(G1)":"card_05(G)";
      break;
  }

  return rtn;
}