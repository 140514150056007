import React, { useEffect, useRef, useState } from "react";
import { GetCardName, GetStageImage } from "@/helper/stageImage";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export function Question() {
    const showCardAudio = useRef<HTMLAudioElement>(null);
    const showAnswerAudio = useRef<HTMLAudioElement>(null);
    const state = useSelector((state: RootState) => state.StageState);
    const [showCard, setShowCard] = useState<string>("show_selected_card");

  useEffect(() => {
    if(state?.isShowCard) {
      setTimeout(() => {
          console.log(showCardAudio)
          showCardAudio.current?.play()
        setShowCard("show_selected_card show_selected_card_show");
      }, 1500)
    }else {
      setShowCard("show_selected_card");
    }
  }, [state?.isShowCard]);

  useEffect(() => {
    if (state?.isShowAns) {
      showAnswerAudio.current?.play()
    } else {
      showAnswerAudio.current?.pause();
      showAnswerAudio.current!.currentTime = 0;
    }
  }, [state?.isShowAns])

  function Quest(quest: number) {
    let Q_A = `quest_a QA_${quest}`;
    let Q_B = `quest_b QB_${quest}`;
    let Q_C = `quest_c QC_${quest}`;

    let img_A = GetStageImage(`Q${quest}_A`)
    let img_B = GetStageImage(`Q${quest}_B`)
    let img_C = GetStageImage(`Q${quest}_C`)
    if(state?.isShowAns && quest === 1) {
      img_A = GetStageImage(`Q1_right`)
    }
    else if(state?.isShowAns && quest === 2) {
      img_C = GetStageImage(`Q2_right`)
    }
    else if(state?.isShowAns && quest === 3) {
      img_B = GetStageImage(`Q3_right`)
    }
    else if(state?.isShowAns && quest === 4) {
      img_A = GetStageImage(`Q4_right`)
    }
    else if(state?.isShowAns && quest === 5) {
      img_C = GetStageImage(`Q5_right`)
    }

    return (
      <div>
          <audio ref={showCardAudio} src="/static/sound/15510298_MotionElements_wizzard-magic-spell.wav"
                 autoPlay={false} playsInline={true}></audio>
        <audio ref={showAnswerAudio} src="/static/sound/27147707_MotionElements_correct-answer-sound.wav"
               autoPlay={false} playsInline={true}></audio>
        <img className="question_background" src={GetStageImage(`background_Q${quest}`)} alt={"bg"}/>
        <img className="quest_img" src={GetStageImage(`quest_img_Q${quest}`)} alt={"bg"}/>
        <video className={state?.isShowAns?"video_ans":"video_ans d-none"} src={`/static/video/q${quest}.mp4`}
               muted={true} autoFocus={true} autoPlay={true} playsInline={true} loop={true} />
        <img className="quest_text" src={GetStageImage(`Q${quest}_text`)} alt={"bg"}/>
        <img className={Q_A} src={img_A} alt={"bg"}/>
        <img className={Q_B} src={img_B} alt={"bg"}/>
        <img className={Q_C} src={img_C} alt={"bg"}/>
        <img className={state?.isShowAns?`quest_a Q${quest}A_text`:"d-none"} src={GetStageImage(`Q${quest}A_text`)} alt={"bg"}/>
        <img className={state?.isShowAns?`quest_b Q${quest}B_text`:"d-none"} src={GetStageImage(`Q${quest}B_text`)} alt={"bg"}/>
        <img className={state?.isShowAns?`quest_c Q${quest}C_text`:"d-none"} src={GetStageImage(`Q${quest}C_text`)} alt={"bg"}/>
      </div>
    )
  }

  return (
    <div className={state?.mainStageSelectCard?"question position-relative":"d-none"}>
      <img className="w-100" src={GetStageImage("BG")} alt={"bg"}/>
      <img className="rocket_img" src={GetStageImage("Rocket")} alt={"bg"}/>
      {/*{卡面畫面}*/}
      <div className={state?.isSelectCard?"":"d-none"}>
        <img className="select_card card01" src={GetStageImage("card_01(R1)")} alt={"bg"}/>
        <img className="select_card card02" src={GetStageImage("card_02(B1)")} alt={"bg"}/>
        <img className="select_card card03" src={GetStageImage("card_03(P1)")} alt={"bg"}/>
        <img className="select_card card04" src={GetStageImage("card_04(Y1)")} alt={"bg"}/>
        <img className="select_card card05" src={GetStageImage("card_05(G1)")} alt={"bg"}/>
      </div>

      {/*{選中卡面畫面}*/}
      <div className={state?.isShowCard?"":"d-none"}>
        <img className="wave" src={GetStageImage("blackhole")} alt={"bg"}/>
        <img className="base" src={GetStageImage("base")} alt={"bg"}/>
        <div className="selected_card overflow-hidden">
          <img style={{opacity: 0}} src={GetStageImage("card_05(G)")} alt={"bg"}/>
          <img className={showCard} src={GetStageImage(GetCardName(state?.questNumber, false))} alt={"bg"}/>
        </div>

        <img className="base_light" src={GetStageImage("base_light")} alt={"bg"}/>
      </div>

      {/*{問題畫面}*/}
      <div className={state?.isShowQuest?"":"d-none"}>
        {Quest(state?.questNumber)}
      </div>
    </div>
  )
}