export const StageImage = [
    {
        id: 1,
        name: "background",
        src: "/static/image/title/background.png"
    },
    {
        id: 2,
        name: "Star",
        src: "/static/image/title/Star.png"
    },
    {
        id: 3,
        name: "background_cire",
        src: "/static/image/title/background_cire.png"
    },
    {
        id: 4,
        name: "planet_01",
        src: "/static/image/title/planet_01.png"
    },
    {
        id: 5,
        name: "planet_02",
        src: "/static/image/title/planet_02.png"
    },
    {
        id: 6,
        name: "planet_03",
        src: "/static/image/title/planet_03.png"
    },
    {
        id: 7,
        name: "loading_background",
        src: "/static/image/title/loading_background.png"
    },
    {
        id: 8,
        name: "loading",
        src: "/static/image/title/loading.png"
    },
    {
        id: 9,
        name: "T",
        src: "/static/image/title/T.png"
    },
    {
        id: 10,
        name: "G",
        src: "/static/image/title/G.png"
    },
    {
        id: 11,
        name: "8",
        src: "/static/image/title/8.png"
    },
    {
        id: 12,
        name: "BG",
        src: "/static/image/stage_card_view/BG.png"
    },
    {
        id: 13,
        name: "card_01(R)",
        src: "/static/image/stage_card_view/card_01(R).png"
    },
    {
        id: 14,
        name: "card_01(R1)",
        src: "/static/image/stage_card_view/card_01(R1).png"
    },
    {
        id: 15,
        name: "card_02(B)",
        src: "/static/image/stage_card_view/card_02(B).png"
    },
    {
        id: 16,
        name: "card_02(B1)",
        src: "/static/image/stage_card_view/card_02(B1).png"
    },
    {
        id: 17,
        name: "card_03(P)",
        src: "/static/image/stage_card_view/card_03(P).png"
    },
    {
        id: 18,
        name: "card_03(P1)",
        src: "/static/image/stage_card_view/card_03(P1).png"
    },
    {
        id: 19,
        name: "card_04(Y)",
        src: "/static/image/stage_card_view/card_04(Y).png"
    },
    {
        id: 20,
        name: "card_04(Y1)",
        src: "/static/image/stage_card_view/card_04(Y1).png"
    },
    {
        id: 21,
        name: "card_05(G)",
        src: "/static/image/stage_card_view/card_05(G).png"
    },
    {
        id: 22,
        name: "card_05(G1)",
        src: "/static/image/stage_card_view/card_05(G1).png"
    },
    {
        id: 23,
        name: "Rocket",
        src: "/static/image/stage_card_view/Rocket.png"
    },
    {
        id: 24,
        name: "base",
        src: "/static/image/stage_card_view/base.png"
    },
    {
        id: 25,
        name: "base_light",
        src: "/static/image/stage_card_view/base_light.png"
    },
    {
        id: 26,
        name: "blackhole",
        src: "/static/image/stage_card_view/blackhole.png"
    },
    {
        id: 27,
        name: "background_Q1",
        src: "/static/image/stage_question_view/Q1/background.png"
    },
    {
        id: 28,
        name: "quest_img_Q1",
        src: "/static/image/stage_question_view/Q1/quest_img.png"
    },
    {
        id: 29,
        name: "Q1_text",
        src: "/static/image/stage_question_view/Q1/Q.png"
    },
    {
        id: 30,
        name: "Q1_A",
        src: "/static/image/stage_question_view/Q1/A.png"
    },
    {
        id: 31,
        name: "Q1_B",
        src: "/static/image/stage_question_view/Q1/B.png"
    },
    {
        id: 32,
        name: "Q1_C",
        src: "/static/image/stage_question_view/Q1/C.png"
    },
    {
        id: 33,
        name: "quest_img_Q1",
        src: "/static/image/stage_question_view/Q1/quest_img.png"
    },


    {
        id: 34,
        name: "background_Q2",
        src: "/static/image/stage_question_view/Q2/background.png"
    },
    {
        id: 35,
        name: "quest_img_Q2",
        src: "/static/image/stage_question_view/Q2/quest_img.png"
    },
    {
        id: 36,
        name: "Q2_text",
        src: "/static/image/stage_question_view/Q2/Q.png"
    },
    {
        id: 37,
        name: "Q2_A",
        src: "/static/image/stage_question_view/Q2/A.png"
    },
    {
        id: 38,
        name: "Q2_B",
        src: "/static/image/stage_question_view/Q2/B.png"
    },
    {
        id: 39,
        name: "Q2_C",
        src: "/static/image/stage_question_view/Q2/C.png"
    },
    {
        id: 40,
        name: "quest_img_Q2",
        src: "/static/image/stage_question_view/Q2/quest_img.png"
    },

    {
        id: 41,
        name: "background_Q3",
        src: "/static/image/stage_question_view/Q3/background.png"
    },
    {
        id: 42,
        name: "quest_img_Q3",
        src: "/static/image/stage_question_view/Q3/quest_img.png"
    },
    {
        id: 43,
        name: "Q3_text",
        src: "/static/image/stage_question_view/Q3/Q.png"
    },
    {
        id: 44,
        name: "Q3_A",
        src: "/static/image/stage_question_view/Q3/A.png"
    },
    {
        id: 45,
        name: "Q3_B",
        src: "/static/image/stage_question_view/Q3/B.png"
    },
    {
        id: 46,
        name: "Q3_C",
        src: "/static/image/stage_question_view/Q3/C.png"
    },
    {
        id: 47,
        name: "quest_img_Q3",
        src: "/static/image/stage_question_view/Q3/quest_img.png"
    },

    {
        id: 48,
        name: "background_Q4",
        src: "/static/image/stage_question_view/Q4/background.png"
    },
    {
        id: 49,
        name: "quest_img_Q4",
        src: "/static/image/stage_question_view/Q4/quest_img.png"
    },
    {
        id: 50,
        name: "Q4_text",
        src: "/static/image/stage_question_view/Q4/Q.png"
    },
    {
        id: 51,
        name: "Q4_A",
        src: "/static/image/stage_question_view/Q4/A.png"
    },
    {
        id: 52,
        name: "Q4_B",
        src: "/static/image/stage_question_view/Q4/B.png"
    },
    {
        id: 53,
        name: "Q4_C",
        src: "/static/image/stage_question_view/Q4/C.png"
    },
    {
        id: 54,
        name: "quest_img_Q4",
        src: "/static/image/stage_question_view/Q4/quest_img.png"
    },

    {
        id: 55,
        name: "background_Q5",
        src: "/static/image/stage_question_view/Q5/background.png"
    },
    {
        id: 56,
        name: "quest_img_Q5",
        src: "/static/image/stage_question_view/Q5/quest_img.png"
    },
    {
        id: 57,
        name: "Q5_text",
        src: "/static/image/stage_question_view/Q5/Q.png"
    },
    {
        id: 58,
        name: "Q5_A",
        src: "/static/image/stage_question_view/Q5/A.png"
    },
    {
        id: 59,
        name: "Q5_B",
        src: "/static/image/stage_question_view/Q5/B.png"
    },
    {
        id: 60,
        name: "Q5_C",
        src: "/static/image/stage_question_view/Q5/C.png"
    },
    {
        id: 61,
        name: "quest_img_Q5",
        src: "/static/image/stage_question_view/Q5/quest_img.png"
    },

    {
        id: 62,
        name: "rocket_background",
        src: "/static/image/stage_rocket_launch/BG.png"
    },
    {
        id: 63,
        name: "rocket_hint",
        src: "/static/image/stage_rocket_launch/hint.png"
    },
    {
        id: 64,
        name: "rocket_progress_background",
        src: "/static/image/stage_rocket_launch/progress_background.png"
    },
    {
        id: 65,
        name: "rocket_progress",
        src: "/static/image/stage_rocket_launch/progress.png"
    },
    {
        id: 66,
        name: "rocket_state1",
        src: "/static/image/stage_rocket_launch/keep_going.png"
    },
    {
        id: 67,
        name: "rocket_state2",
        src: "/static/image/stage_rocket_launch/keep_it_up.png"
    },
    {
        id: 68,
        name: "rocket_state3",
        src: "/static/image/stage_rocket_launch/fighting.png"
    },
    {
        id: 69,
        name: "img_5987",
        src: "/static/image/title/5987.jpg"
    },
    {
        id: 70,
        name: "Q1_right",
        src: "/static/image/stage_question_view/Q1/right.png"
    },
    {
        id: 71,
        name: "Q2_right",
        src: "/static/image/stage_question_view/Q2/right.png"
    },
    {
        id: 72,
        name: "Q3_right",
        src: "/static/image/stage_question_view/Q3/right.png"
    },
    {
        id: 73,
        name: "Q4_right",
        src: "/static/image/stage_question_view/Q4/right.png"
    },
    {
        id: 74,
        name: "Q5_right",
        src: "/static/image/stage_question_view/Q5/right.png"
    },

    {
        id: 72,
        name: "Q1A_text",
        src: "/static/image/stage_question_view/Q1/A_Text.png"
    },
    {
        id: 73,
        name: "Q1B_text",
        src: "/static/image/stage_question_view/Q1/B_Text.png"
    },
    {
        id: 74,
        name: "Q1C_text",
        src: "/static/image/stage_question_view/Q1/C_Text.png"
    },

    {
        id: 72,
        name: "Q2A_text",
        src: "/static/image/stage_question_view/Q2/A_Text.png"
    },
    {
        id: 73,
        name: "Q2B_text",
        src: "/static/image/stage_question_view/Q2/B_Text.png"
    },
    {
        id: 74,
        name: "Q2C_text",
        src: "/static/image/stage_question_view/Q2/C_Text.png"
    },

    {
        id: 72,
        name: "Q3A_text",
        src: "/static/image/stage_question_view/Q3/A_Text.png"
    },
    {
        id: 73,
        name: "Q3B_text",
        src: "/static/image/stage_question_view/Q3/B_Text.png"
    },
    {
        id: 74,
        name: "Q3C_text",
        src: "/static/image/stage_question_view/Q3/C_Text.png"
    },

    {
        id: 72,
        name: "Q4A_text",
        src: "/static/image/stage_question_view/Q4/A_Text.png"
    },
    {
        id: 73,
        name: "Q4B_text",
        src: "/static/image/stage_question_view/Q4/B_Text.png"
    },
    {
        id: 74,
        name: "Q4C_text",
        src: "/static/image/stage_question_view/Q4/C_Text.png"
    },

    {
        id: 72,
        name: "Q5A_text",
        src: "/static/image/stage_question_view/Q5/A_Text.png"
    },
    {
        id: 73,
        name: "Q5B_text",
        src: "/static/image/stage_question_view/Q5/B_Text.png"
    },
    {
        id: 74,
        name: "Q5C_text",
        src: "/static/image/stage_question_view/Q5/C_Text.png"
    }
]

export const GetStageImage = (name: string) => {
    return StageImage.find(item => item.name === name)!.src;
}

export const GetCardName = (quest: number, isSelectCard: boolean) => {
  let rtn = "";
  switch (quest){
    case 1:
      rtn = isSelectCard?"card_01(R1)":"card_01(R)";
      break;
    case 2:
      rtn = isSelectCard?"card_02(B1)":"card_02(B)";
      break;
    case 3:
      rtn = isSelectCard?"card_03(P1)":"card_03(P)";
      break;
    case 4:
      rtn = isSelectCard?"card_04(Y1)":"card_04(Y)";
      break;
    case 5:
      rtn = isSelectCard?"card_05(G1)":"card_05(G)";
      break;
  }

  return rtn;
}