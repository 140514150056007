import React from "react";
import { GetViewerImage } from "@/helper/viewerImage";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export function Wait() {
  const state = useSelector((state: RootState) => state.ViewerState);

  return (
        <div className={state?.isWaiting?"wait_view":"d-none"}>
            <img className="background" src={GetViewerImage("background")} alt={"bg"} />
            <img className="imageSet background_start" src={GetViewerImage("background_star")} alt={"bg"} />

            <img className="imageSet message_box" src={GetViewerImage("message_box")} alt={"bg"} />
            <img className="imageSet ricket" src={GetViewerImage("ricket")} alt={"bg"} />
            <img className="imageSet black_hole" src={GetViewerImage("black_hole")} alt={"bg"} />
            <img className="imageSet earth" src={GetViewerImage("earth")} alt={"bg"} />
        </div>
    )
}