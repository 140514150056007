import React, { useEffect, useRef, useState } from "react";
import { GetViewerImage } from "@/helper/viewerImage";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
export function Rocket() {
  const state = useSelector((state: RootState) => state.ViewerState);
  const [loadingStyle, setLoadingStyle] = useState<string>("loading");
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if(state?.isLaunch){
      setTimeout(() => {
        videoRef.current!.play()
      }, 8330)
      setTimeout(() => {
        setLoadingStyle("loading loading_set");
      }, 500);
    }else {
      setLoadingStyle("loading");
    }
  }, [state?.isLaunch]);

    return (
      <>
        {
          state?.isShowLaunchRocket?
            <div className="rocket_view">
              <img className="background" src={GetViewerImage("quest_background")} alt={"bg"} />
              <video className={"background_video"} ref={videoRef}
                     src={"/static/image/stage_rocket_launch/rocket_launch.mp4"}
                     muted={true} autoFocus={true} autoPlay={false} playsInline={true} />

              <img className="hint" src={GetViewerImage("hint")} alt={"bg"} />
              <img className="loading_layout" src={GetViewerImage("loading_layout")} alt={"bg"} />
              <img className={loadingStyle} src={GetViewerImage("loading")} alt={"bg"} />
              <img className="keep_it_up" src={GetViewerImage("keep_it_up")} alt={"bg"} />
            </div>
            :
            null
        }
      </>

    )
}