import React, { useEffect, useState } from "react";
import { Question, Rocket, Title } from "@/component/Stage";
import { StageImage } from "@/helper/stageImage";

import "@/scss/stage.scss"
import { useDispatch } from "react-redux";
import { Dispatch } from "@/store";
import { AnimationView } from "@/component/Stage/AnimationView";
import { ImageView } from "@/component/Stage/ImageView";
export function Stage() {
    const dispatch = useDispatch<Dispatch>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
          dispatch.StageState.setSocketConnect();
        }, 2000);
        const loadImage = (image:{id: number, src: string}) => {
            return new Promise((resolve, reject)=> {
                const loadImg = new Image();
                loadImg.src = image.src;
                loadImg.onload = () => {
                    resolve(image.src)
                }

                loadImg.onerror = err => reject(err);
            })
        }

        Promise.all(StageImage.map(image => loadImage(image)))
            .then(() => {
                setLoading(false)
            })
    },[])

    return (
        <>
            {loading? null
                :
                <>
                    <Title />
                    <ImageView />
                    <AnimationView />
                    <Question />
                    <Rocket />
                </>
            }
        </>


    )
}