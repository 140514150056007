import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "@/store";
import { RouterProvider } from "react-router-dom";
import Routers from "@/routers";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={Routers}/>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
