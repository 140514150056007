import React, { useEffect, useState } from "react";
import { GetCardName, GetViewerImage } from "@/helper/viewerImage";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export function Question() {
  const [showCard, setShowCard] = useState<string>("selected_card");
  const [selectAns , setSelectAns] = useState<string>("")
  const state = useSelector((state: RootState) => state.ViewerState);

  useEffect(() => {
    if(state?.isShowCard) {
      setTimeout(async ()=> {
        setShowCard("selected_card show_selected_card");
      }, 1500);
    }else {
      setShowCard("selected_card");
    }
  }, [state?.isShowCard]);

  useEffect(() => {
      setSelectAns("");
  }, [state?.isShowQuest])

  function onClickAns(Ans: number) {
      if(state?.isShowAns){
          return;
      }
    window.localStorage.setItem(`select_ans_q${state?.selectedNumber}`, Ans.toString())
    setSelectAns(Ans.toString())
  }

  function Quest(quest: number) {
    const Q = `quest_text quest_${quest}`;
    const Q_A = `quest_A QA_${quest}`
    const Q_A_Ans = state?.isShowAns&&selectAns==="1"?`quest_A_ans QA_${quest}_Ans`:'d-none';
    const Q_B = `quest_B QB_${quest}`;
    const Q_B_Ans = state?.isShowAns&&selectAns==="2"?`quest_B_ans QB_${quest}_Ans`:'d-none';
    const Q_C = `quest_C QC_${quest}`;
    const Q_C_Ans = state?.isShowAns&&selectAns==="3"?`quest_C_ans QC_${quest}_Ans`:'d-none';

    return (
      <div className="quest_area">
        <img className={"quest_background"} src={GetViewerImage(`background_Q${quest}`)} alt={"bg"} />
        <img className={Q} src={GetViewerImage(`quest_img_Q${quest}`)} alt={"bg"} />
        <video className={state?.isShowAns?`video_ans q${quest}_ans`:"video_ans d-none"} src={`/static/video/q${quest}.mp4`}
               muted={true} autoFocus={true} autoPlay={true} playsInline={true} loop={true} />
        <img className={Q_A} src={GetViewerImage(selectAns!=="1"?`Q${quest}_A`:`Q${quest}_A_Select`)} onClick={() => onClickAns(1)} alt={"bg"} />
        <img className={Q_A_Ans} src={GetViewerImage(`Q${quest}_A_Ans`)} alt={"bg"} />
        <img className={Q_B} src={GetViewerImage(selectAns!=="2"?`Q${quest}_B`:`Q${quest}_B_Select`)} onClick={() => onClickAns(2)} alt={"bg"} />
        <img className={Q_B_Ans} src={GetViewerImage(`Q${quest}_B_Ans`)} alt={"bg"} />
        <img className={Q_C} src={GetViewerImage(selectAns!=="3"?`Q${quest}_C`:`Q${quest}_C_Select`)} onClick={() => onClickAns(3)} alt={"bg"} />
        <img className={Q_C_Ans} src={GetViewerImage(`Q${quest}_C_Ans`)} alt={"bg"} />
      </div>
    )
  }

  return (
    <div className={state?.isSelectingCard?"question_view":"d-none"}>
      <img className="background" src={GetViewerImage("quest_background")} alt={"bg"} />

      {/*{卡面畫面}*/}
      <div className={state?.isShowSelectedCard?"":"d-none"}>
          <img className="imageSet card01" src={GetViewerImage("card_01(R1)")} alt={"bg"} />
          <img className="imageSet card02" src={GetViewerImage("card_02(B1)")} alt={"bg"} />
          <img className="imageSet card03" src={GetViewerImage("card_03(P1)")} alt={"bg"} />
          <img className="imageSet card04" src={GetViewerImage("card_04(Y1)")} alt={"bg"} />
          <img className="imageSet card05" src={GetViewerImage("card_05(G1)")} alt={"bg"} />
      </div>

      {/*{選中卡面畫面}*/}
      <div className={state?.isShowCard?"":"d-none"}>
        <img className="background_wave" src={GetViewerImage("base_wave_select_card")} alt={"bg"} />
        <img className="imageSet base" src={GetViewerImage("base_select_card")} alt={"bg"} />
        <div className="imageSet select_card overflow-hidden">
          <img className={"w-100"} style={{opacity: 0}} src={GetViewerImage("card_01(R)")} alt={"bg"} />
          <img className={showCard} src={GetViewerImage(GetCardName(state?.selectedNumber!, false))} alt={"bg"} />
        </div>
        <img className="imageSet base_light" src={GetViewerImage("base_light_select_card")} alt={"bg"} />
      </div>

      {/*{問題畫面}*/}
      {
        state?.isShowQuest?
          Quest(state?.selectedNumber!):null
      }
    </div>
  )
}