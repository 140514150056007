import { Models } from "@rematch/core";
import { StageState } from "@/model/stageState";
import { ViewerState } from "@/model/viewerState";
import { ControllerState } from "@/model/ControllerState";

export interface RootModel extends Models<RootModel>{
  StageState: typeof StageState,
  ViewerState: typeof ViewerState,
  ControllerState: typeof ControllerState
}

export const models: RootModel = {
  StageState,
  ViewerState,
  ControllerState
}