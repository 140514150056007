import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";

export const ControllerState = createModel<RootModel>()({
  state: {},
  reducers: {
    setState(state, payload: { type: string, data: any }) {
      const {type, data} = payload;
      return {...state, [type]: data};
    }
  },
  effects: (dispatch) => ({})
});