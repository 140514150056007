import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import 'mind-ar/dist/mindar-face-three.prod'
import { GetViewerImage } from "@/helper/viewerImage";
import { imageTake } from "@/helper/imageTake";
import * as THREE from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

let faceArLib: any = null;
const clock = new THREE.Clock();
const mixer:any[] = [];
const animationList: any[] = [];
let scoreAR = 100;
function startFaceArLib() {
    //@ts-ignore
    faceArLib = new window.MINDAR.FACE.MindARThree({
        container: document.querySelector("#ar_container_face"),
        filterMinCF: 0.001,
        filterBeta: 0.009,
        missTolerance: 5,
        warmupTolerance: 0,
        uiError: false,
        uiLoading: false,
        uiScanning: false
    })
    const {renderer, scene, camera} = faceArLib;
    const loader = new GLTFLoader();
    Promise.all([
        loader.loadAsync(`/static/model/Spaceship_${scoreAR}.glb`),
        loader.loadAsync("/static/model/headOccluder.glb"),
        faceArLib.start(),
    ]).then(([spaceship, headOccluder , arLibResult]) => {
        const anchor = faceArLib.addAnchor(1);
        const {renderer, scene, camera} = faceArLib;
        setWebCam(faceArLib)

        setScene(anchor.group, scene, spaceship,headOccluder, (modelObj) => {
            //@ts-ignore
            window.faceArLib = faceArLib
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            renderer.shadowMap.needsUpdate = true;
            renderer.setAnimationLoop(() => {
                // console.log(sphere.position)
                renderer.autoClear = false;
                camera.layers.set(2);
                renderer.render(scene, camera);
                camera.layers.set(0);
                renderer.render(scene, camera);
                let mixerUpdateDelta = clock.getDelta();
                modelObj.rotation.y -= mixerUpdateDelta * 1;
                Object.keys(mixer).forEach(name => {
                    //@ts-ignore
                    mixer[name].update(mixerUpdateDelta)
                })
            })
        })
    })
}

function setWebCam(mindarThree: any) {
    const { video, scene } = mindarThree;
    // video.style.opacity = 0;
    //建立影像圖層
    let videoTex = new THREE.VideoTexture(video);
    videoTex.colorSpace = THREE.SRGBColorSpace;
    videoTex.minFilter = THREE.LinearFilter;

    const mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(video.clientWidth, video.clientHeight),
        new THREE.MeshBasicMaterial({ color: 0xffffff, map: videoTex, side: THREE.DoubleSide})
    );

    let scale = 11;
    let position_y = 0;
    mesh.position.set(0, position_y, -10000);
    mesh.scale.set(-scale, scale, 1);
    mesh.layers.set(2);
    // mesh.layers.enable(2);
    // scene.add(mesh);
}

function setScene(anchorGroup: THREE.Group, scene: THREE.Scene, model: GLTF, headOccluder: GLTF, callback: (model: THREE.Object3D)=>void) {
    const modelObject = new THREE.Object3D();
    modelObject.add(model.scene)
    modelObject.scale.set(0.15, 0.15, 0.15);
    modelObject.position.y = 0;
    modelObject.position.z = 0;
    model.scene.position.y = 0.5;
    model.scene.position.z = 4.5;
    anchorGroup.add(modelObject);
    const light = new THREE.AmbientLight(0xffffff, 1.0);
    scene.add(light)
    const dl = new THREE.DirectionalLight(0xffffff, 1.0)
    dl.position.y = 10
    dl.position.z = 10
    scene.add(dl)
    headOccluder.scene.scale.set(0.09, 0.09, 0.09);
    headOccluder.scene.position.set(0, 0, 0)

    const occlusionMat = new THREE.ShaderMaterial({
        vertexShader: THREE.ShaderLib.basic.vertexShader,
        fragmentShader: "precision lowp float;\n void main(void){\n gl_FragColor = vec4(1.,0.,0.,1.);\n }",
        uniforms: THREE.ShaderLib.basic.uniforms,
        side: THREE.DoubleSide,
        colorWrite: false
    });
    headOccluder.scene.renderOrder = -1e12; // render first
    //@ts-ignore
    headOccluder.scene.children[0].material = occlusionMat;

    anchorGroup.add(headOccluder.scene)
    model.animations.forEach(item => {
        console.log(item)
        //@ts-ignore
        mixer[item.name] = new THREE.AnimationMixer(model.scene);
        //@ts-ignore
        animationList[item.name] = mixer[item.name].clipAction(item);
        //@ts-ignore
        animationList[item.name].play();
        //@ts-ignore
        animationList[item.name].loop = THREE.LoopRepeat;
    })

    callback(modelObject);
}

export function FaceTrackARView() {
    const state = useSelector((state: RootState) => state.ViewerState);
    const [scoreState, setScoreState] = useState<string>("")
    const [imageData, setImageData] = useState<string>("");

    useEffect(() => {
        if(state?.isShowEndAr) {
            getScores();
            if (faceArLib){
                faceArLib.start();
            } else {
                startFaceArLib();
            }
        }
        else {
            if(faceArLib) {
                faceArLib.stop();
            }
        }
    }, [state?.isShowEndAr])

    function getScores() {
        let score = 100;
        const A1 = localStorage.getItem(`select_ans_q1`) ?? "0"
        const A2 = localStorage.getItem(`select_ans_q2`) ?? "0"
        const A3 = localStorage.getItem(`select_ans_q3`) ?? "0"
        const A4 = localStorage.getItem(`select_ans_q4`) ?? "0"
        const A5 = localStorage.getItem(`select_ans_q5`) ?? "0"
        score -= A1==="1"?0:10
        score -= A2==="3"?0:10
        score -= A3==="2"?0:10
        score -= A4==="1"?0:10
        score -= A4==="3"?0:10
        scoreAR = score
        setScoreState(score.toString())
    }

    function OnClickImage() {
        console.log(`take image`)
        if(faceArLib){
            const data = imageTake(faceArLib)
            setImageData(data[0] as string);
        }
    }

    function OnClickCloseImage() {
        setImageData("")
    }

    return (
        <div className={state?.isShowEndAr?"ar_view":"d-none"}>
            <div id="ar_container_face" style={{ width: "100%", height: "100vh" }} />
            <img className="score_text" src={GetViewerImage("score_text")} alt={"bg"} />
            {
                scoreState? <img className="score" src={GetViewerImage(scoreState)} alt={"bg"} /> : null
            }
            <img className="c_left_up" src={GetViewerImage("c_left_up")} alt={"bg"} />
            <img className="c_right_up" src={GetViewerImage("c_right_up")} alt={"bg"} />
            <img className="c_left_down" src={GetViewerImage("c_left_down")} alt={"bg"} />
            <img className="c_right_down" src={GetViewerImage("c_right_down")} alt={"bg"} />
            <img className="text" src={GetViewerImage("text_shot")} alt={"bg"} />
            <img className="take-image" onClick={OnClickImage} src={GetViewerImage("button_shot")} alt={"bg"} />

            {
                imageData!=""?
                    <div className="image-view">
                        <span className="imageSet save_text">長按照片儲存</span>
                        <div className="w-100 imageSet" style={{height: "50%"}}>
                            <img className="h-100 imageSet" src={imageData} alt={"bg"} />
                            <img className="c_left_up" style={{top: 0, width: '20%'}} src={GetViewerImage("c_left_up")} alt={"bg"} />
                            <img className="c_right_up" style={{top: 0, width: '20%'}} src={GetViewerImage("c_right_up")} alt={"bg"} />
                            <img className="c_left_down" style={{bottom: 0, width: '20%'}} src={GetViewerImage("c_left_down")} alt={"bg"} />
                            <img className="c_right_down" style={{bottom: 0, width: '20%'}} src={GetViewerImage("c_right_down")} alt={"bg"} />
                        </div>
                        <img className="imageSet close-image" onClick={OnClickCloseImage}
                             src={GetViewerImage("button_close")} alt={"bg"} />
                    </div>
                    :null
            }
        </div>

    )
}