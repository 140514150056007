import { createModel, RematchDispatch } from "@rematch/core";
import { RootModel } from "@/model/index";
import { Socket } from "socket.io-client";
import { initSocketIO } from "@/helper/socket";

interface IStageState {
  mainStage1: boolean;
  mainStage2: boolean;
  mainStageSelectCard: boolean;
  isSelectCard: boolean;
  isShowCard: boolean;
  isShowQuest: boolean;
  isShowAns: boolean;
  mainStageLaunchRocket: boolean;
  questNumber: number;
  animationView: boolean;
  imageView: boolean;
  isLaunch: boolean;
}

let socketIO: Socket|null = null;

export const StageState = createModel<RootModel>()({
  state: {
    isLoading: true,
    mainStage1: true,
    mainStage2: false,
    mainStageSelectCard: false,
    isSelectCard: false,
    isShowCard: false,
    isShowQuest: false,
    isShowAns: false,
    mainStageLaunchRocket: false,
    questNumber: 1,
    animationView: false,
    imageView: false,
    isLaunch: false
  } as IStageState,
  reducers: {
    setState(state, payload: { type: string, data: any }) {
      const {type, data} = payload;
      return {...state, [type]: data};
    },
    setData(state, payload: IStageState) {
      console.log({...state, payload})
      return {...state, ...payload};
    }
  },
  effects: (dispatch) => ({
    setSocketConnect() {
      if(socketIO == null) {
        socketIO = initSocketIO()
        setWebSocketEvent(dispatch);
      }
    }
  })
});

function setWebSocketEvent(dispatch: RematchDispatch<RootModel>) {
  if(socketIO === null){
    return;
  }

  socketIO.on("connect", () => {

  });

  socketIO.on("switch_stage", (data: IStageState) => {
    dispatch.StageState.setData(data);
  })
}